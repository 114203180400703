<template>
  <b-link
    @click="$router.push('/')"
  >
    <b-avatar
      src="/auth-logo.png"
      size="5rem"
    />
  </b-link>
</template>

<script>
import {
  BLink, BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'AuthLogo',
  components: {
    BLink,
    BAvatar,
  },
}
</script>
