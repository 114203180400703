<template>
  <b-overlay
    :show="submitting"
    spinner-variant="primary"
    spinner-type="grow"
    :variant="darkMode?'dark':'white'"
    spinner-small
    rounded="md"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0 justify-content-center">
        <lenon-auth-logo />
        <div
          class="col-12 col-lg-6 col-xl-5 d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <div
            class="col-12 col-sm-8 col-md-6 col-lg-12 col-xl-10 px-xl-2 mx-auto"
          >
            <p class="text-center">
              <auth-logo />
            </p>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 text-center"
            >
              {{ $t('welcome_to') }} Lenon
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              {{ 'Please provide your email address, we will reset and send you a new password.' }}
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="loginValidation"
              v-slot="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="reset()"
              >
                <lenon-input
                  v-model="email"
                  name="email"
                  rules="required|email"
                  label="Email"
                />
                <!-- submit buttons -->
                <div class="mt-1 d-flex justify-content-between align-items-start">
                  <lenon-button
                    variant="outline-warning"
                    label="Login"
                    icon="ArrowLeftCircleIcon"
                    @onClick="()=>{$router.push({name: 'login'})}"
                  />
                  <lenon-button
                    class="float-right"
                    :disabled="invalid"
                    label="Reset"
                    type="submit"
                  />
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCardText,
  BCardTitle,
  BForm,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useAppConfig from '@core/app-config/useAppConfig'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import LenonAuthLogo from '@/lenon/components/LenonAuthLogo.vue'
// eslint-disable-next-line import/no-cycle
import { RESET_M } from '@/graphql/mutations'
import showToast from '@/lenon/mixins/showToast'
import AuthLogo from '@/lenon/components/AuthLogo.vue'

const { skin } = useAppConfig()

export default {
  components: {
    AuthLogo,
    LenonButton,
    LenonInput,
    BRow,
    BCardText,
    BCardTitle,
    BForm,
    ValidationObserver,
    BOverlay,
    LenonAuthLogo,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      submitting: false,
      email: null,
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
  },
  methods: {
    reset() {
      this.submitting = true
      this.$apollo.mutate({
        mutation: RESET_M,
        variables: {
          email: this.email,
        },
      }).then(res => {
        this.submitting = false
        if (!res.errors) {
          this.showSuccess('Your password was reset successfully, please check your email for your new password and login.')
          this.$router.push({ name: 'login' })
        } else {
          this.showError('Something went wrong, please try again')
        }
      }).catch(error => {
        this.submitting = false
        console.error(error)
        this.showError('Something went wrong, please try again')
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
