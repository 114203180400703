<template>
<!--    <b-link class="brand-logo" @click="$router.push('/')">-->
<!--          <logo />-->
<!--          <h2 class="brand-text text-primary ml-1">-->
<!--            Lenon-->
<!--          </h2>-->
<!--        </b-link>-->
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
export default{
    name: 'LenonAuthLogo',
    components:{
       BLink,
       Logo
    }
}
</script>
